/* PrivacyPolicyPage.css */
.privacy-policy-container {
    font-family: 'Roboto', sans-serif;
    padding: 2rem;
    color: #fff; /* Default text color to match your site */
}

.privacy-policy-intro {
    background-color: #2a2a2a; /* Matches the background color from other sections */
    padding: 2rem;
    border-radius: 8px;
    margin-bottom: 2rem;
}

.privacy-policy-intro h1 {
    font-size: 2.5rem;
    color: #fff; /* Matches your header text color */
    margin-bottom: 1rem;
}

.privacy-policy-content {
    font-size: 1rem;
    color: #ccc; /* Matches your other content text color */
}

.privacy-policy-section {
    background-color: #333; /* Matches the card background color */
    padding: 2rem;
    border-radius: 8px;
    margin-bottom: 2rem;
    border: 1px solid #555; /* Matches the border color from your cards */
}

.privacy-policy-section h2 {
    font-size: 2rem;
    color: #007BFF; /* Matches your primary button color */
    margin-bottom: 1rem;
}

.privacy-policy-section ul {
    list-style-type: disc;
    margin-left: 1.5rem;
    color: #ccc; /* Ensures list text matches your content color */
}

.privacy-policy-section ul li {
    margin-bottom: 0.5rem;
}

.privacy-policy-section p {
    font-size: 1rem;
    color: #ccc; /* Consistent with the rest of the page text color */
}

@media (max-width: 768px) {
    .privacy-policy-container {
        padding: 1rem;
    }

    .privacy-policy-intro h1,
    .privacy-policy-section h2 {
        font-size: 1.8rem;
    }

    .privacy-policy-content,
    .privacy-policy-section p {
        font-size: 0.9rem;
    }

    .privacy-policy-section ul {
        margin-left: 1rem;
    }

    .privacy-policy-section ul li {
        margin-bottom: 0.3rem;
    }
}
