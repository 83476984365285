.dashboard-container {
    font-family: 'Roboto', sans-serif;
    padding: 2rem;
}

h1 {
    font-size: 2.5rem;
    margin-bottom: 2rem;
}

.main-content {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;
}

.tabs {
    display: flex;
    justify-content: center;
    gap: 2rem;
    margin-bottom: 1rem;
}


.tabs button {
    background-color: #007BFF;
    color: #fff;
    padding: 1rem;
    border: none;
    border-radius: 5px; /* Add rounded corners */
    font-size: 1.1rem;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease; /* Added transform */
}

.tabs button:hover {
    background-color: #0056b3;
    transform: translateY(-2px); /* Hover lift effect */
}

.tabs button.active {
    background-color: #0056b3;
}

.trading-cards-container {
    padding: 15px;
    background-color: #2a2a2a;
    display: grid;
    grid-column: span 1;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 1rem;
    max-height: 100vh;
    overflow-y: scroll;
}


.trading-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #333;
    border: 1px solid #555;
    height: 100%;
    padding: 2rem;
    text-decoration: none;
    text-align: center;
    border-radius: 10px;
    transition: transform 0.3s ease, box-shadow 0.3s ease; /* Existing transition */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add shadow */
}

.icon-container {
    display: flex; /* Align icons in a row */
    gap: 0.5rem; /* Adjust the spacing between icons */
    margin-top: 0.5rem; /* Add some space above the icons */
}

.trading-card:hover {
    transform: translateY(-5px) scale(1.02); /* Slightly grow on hover */
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3); /* Increased shadow on hover */
}


.trend-arrow {
    font-size: 24px; 
    color: #fff;
    margin-left: 5px;
    margin-right: 5px;
    transition: color 0.3s ease;
}


.trading-card h2 {
    text-shadow: 0px 4px 3px rgba(0, 0, 0, 1);
    text-align: center;
    font-size: 2rem;
    margin-bottom: 1rem;
}

.trading-card p {
    text-shadow: 0px 4px 3px rgba(0, 0, 0, 1);

    font-weight: bolder;
    font-size: 1.3rem;
    text-align: center;
    margin-bottom: 0.5rem;
    ;
}

.stop-loss-text {
    font-weight: bolder;
    color: #8c1731;
}

.take-profit-text {
    font-weight: bolder;
    color: rgb(101, 246, 101);
}

.trading-card b {
    color: rgb(255, 255, 255);

}

.Nav-Link {
    text-decoration: none;
}

.error-message {
    text-align: center;
    color: #ff4d4d; /* Red color for errors */
    margin: 1rem 0;
}

.error-message button {
    background-color: #007BFF;
    color: white;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.error-message button:hover {
    background-color: #0056b3;
}

.sorting-controls {
    display: flex;
    justify-content: center;
    gap: 1rem; /* Space between elements */
    margin-bottom: 1rem; /* Space below the controls */
}

.sorting-select,
.sorting-input {
    padding: 0.5rem; /* Padding inside the controls */
    border: 1px solid #555; /* Border color */
    border-radius: 5px; /* Rounded corners */
    background-color: #333; /* Dark background to match your theme */
    color: #fff; /* White text color */
    font-size: 1rem; /* Font size */
    transition: border-color 0.3s; /* Smooth transition for border color */
}

/* Change border color on focus */
.sorting-select:focus,
.sorting-input:focus {
    border-color: #007BFF; /* Change border color on focus */
    outline: none; /* Remove default outline */
}

.sorting-select option {
    background-color: #333; /* Background for select options */
    color: #fff; /* Text color for options */
}

.sorting-icons {
    display: flex; /* Align icons in a row */
    gap: 0.5rem; /* Space between icons */
}

.sort-button {
    background: none; /* No background */
    border: none; /* Remove border */
    cursor: pointer; /* Pointer cursor on hover */
    color: #fff; /* Icon color */
    font-size: 1.5rem; /* Size of icons */
    transition: color 0.3s; /* Smooth color transition */
}

.sort-button.active {
    color: #007BFF; /* Change color for active button */
}

/* Optional: Change icon color on hover */
.sort-button:hover {
    color: #007BFF; /* Change icon color on hover */
}

.filter-icons {
    display: flex; /* Align filter icons in a row */
    gap: 0.5rem; /* Space between icons */
}

.filter-button {
    background: none; /* No background */
    border: none; /* Remove border */
    cursor: pointer; /* Pointer cursor on hover */
    color: #fff; /* Icon color */
    font-size: 1.5rem; /* Size of icons */
    transition: color 0.3s; /* Smooth color transition */
}

.filter-button.active {
    color: #007BFF; /* Change color for active button */
}

/* Optional: Change icon color on hover */
.filter-button:hover {
    color: #007BFF; /* Change icon color on hover */
}

/* Optional: Style for input placeholder */
.sorting-input::placeholder {
    color: #aaa; /* Placeholder color */
    opacity: 0.8; /* Slight transparency */
}




@media (max-width: 768px) {

    .sorting-controls {
        flex-direction: column; /* Stack items vertically */
        align-items: center; /* Center items */
    }

    .sorting-icons,
    .filter-icons {
        flex-wrap: wrap; /* Allow icons to wrap */
        justify-content: center; /* Center icons */
        gap: 0.5rem; /* Space between icons */
    }

    .sorting-input {
        width: 100%; /* Full width on smaller screens */
        max-width: 300px; /* Max width for inputs */
        margin-top: 1rem; /* Space above the input */
    }

    .main-content {
        grid-template-columns: 1fr;
    }

    .tabs {
        flex-direction: row;
        align-items: center;
        gap: 5px;
    }

    .tabs button {
        padding: 10px;
    }


    .trading-cards-container {
        grid-template-columns: 1fr;
        gap: 1rem;
    }


    .trading-card {
        padding: 1rem;
    }

    .trading-card h2 {
        font-size: 1.5rem;
    }

    .trading-card p {
        font-size: 1rem;
    }

    .stop-loss-text,
    .take-profit-text {
        font-size: 0.9rem;
    }

}




