.signup-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #121212;
}

.signup-card {
    background-color: #2a2a2a;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;
    text-align: center;
}

.signup-form label {
    display: block;
    margin: 0.5rem 0 0.2rem;
    color: #f0f0f0;
}

input[type=email].signup-input , input[type=text].signup-input , input[type=password].signup-input  {
    width: 100%;
    padding: 0.8rem;
    margin-bottom: 1rem;
    border-radius: 4px;
    border: 1px solid #555;
    background-color: #333;
    color: #e0e0e0;
}
input[type=email].signup-input:focus,
input[type=text].signup-input:focus,
input[type=password].signup-input:focus  {
    outline: none;
    border-color: #007BFF;
}

button[type=submit].signup-button {
    background-color: #007BFF;
    color: #ffffff;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 20px;
    display: block;
    width: 100%;
}

button[type=submit].signup-button:hover {
    background-color: #0056b3;
}

.login-text {
    margin-top: 1rem;
    color: #ffffff;
}

.login-text a {
    color: #007BFF;
    text-decoration: underline;
}

.login-text a:hover {
    text-decoration: underline;
}

.verification-status {
    color: #e0e0e0;
    margin-top: 1rem;
}
