.home-page {
    text-align: center;
    padding: 2rem 0;
}

.home-page h1{
    color: white;
}


/* --- Benefits Section Styles --- */

.benefits {
    display: flex;
    flex-direction: column;
    align-items: center; /* This will center everything horizontally */
    padding: 2rem 0;
}

.benefits h2{
    color: white;
    flex: 2;
}

.container {
    max-width: 1200px; /* Max container width */
    margin: 0 auto;
    padding: 0 1rem; /* Side padding */
    display: flex;
    flex-wrap: wrap; /* Allows for wrapping if items don't fit */
    justify-content: space-between;
}

.benefits-list {
    display: flex;
    justify-content: space-around; /* This will give equal space between items */
    flex-wrap: wrap; /* This ensures responsiveness, items will wrap when there's no room */
    width: 100%; /* Make sure this element takes the full width available */
}

.benefit {
    flex-basis: 30%; /* This ensures each benefit takes roughly a third of the width, adjust as needed */
    text-align: center;
    margin-top: 1rem; /* Space between each benefit for when they wrap */
}

.benefit .icon-homePage {
    font-size: 3rem; /* Adjust based on icon-homePage size */
    color: #00afff;
    margin-bottom: 1rem;
}

.benefit h3 {
    font-size: 1.5rem;
    color: #f0f0f0;
    margin: 0.5rem 0;
}

.benefit p {
    font-size: 1rem;
    color: #e0e0e0; /* Light gray for better readability on dark background */
}


/* --- Testimonials Section Styles --- */

.testimonials {
    background-color: #333333; /* A slightly different shade for distinction */
    padding: 2rem 0;
}

.testimonial {
    display: flex;
    align-items: flex-start; /* Align to the top */
    margin-bottom: 2rem;
    padding: 1rem;
    background-color: #2a2a2a; /* Consistent with the dark theme */
    border-radius: 8px; /* Rounded corners for aesthetics */
}

.testimonial img {
    width: 80px;
    height: 80px;
    border-radius: 50%; /* Circle crop */
    margin-right: 1.5rem; /* Space between image and content */
    border: 2px solid #00afff; /* Blue border to make images pop a bit */
}

.testimonial p {
    flex: 1; /* Allows the text to take up remaining space */
    color: #e0e0e0; /* Light gray for better readability on dark background */
    font-style: italic; /* To emphasize the testimonial nature */
}

.testimonial h4 {
    font-size: 1.2rem;
    color: #f0f0f0; /* A slightly brighter color than the paragraph */
    margin-top: 0.5rem;
}

.benefit .icon-homePage {
    font-size: 3rem; 
    color: #00afff;
    margin-bottom: 1rem;
}

@media only screen and (max-width: 1024px) {
    /* Styles for tablets and large phones */
    .container {
        padding: 0 0.5rem;
    }

    .benefit {
        flex-basis: 48%; /* Adjusted basis for 2 items per row */
    }
}

@media only screen and (max-width: 768px) {
    /* Styles for phones and smaller devices */
    .container {
        padding: 0 0.5rem;
    }

    .benefit {
        flex-basis: 100%; /* Adjusted basis for 1 item per row */
    }

    .testimonial img {
        width: 60px;
        height: 60px;
    }

}
