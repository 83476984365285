.new-trader-container {
    font-family: 'Roboto', sans-serif;
    background-color: #2a2a2a;
    color: #fff;
    padding: 0;
    margin: 0;
}

.hero-trader-section {
    text-align: center;
    padding: 3rem 1rem;
    background-color: #007bff;
    color: #fff;
}

.hero-trader-section h1 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
}

.hero-trader-section p {
    font-size: 1.2rem;
    margin-bottom: 2rem;
}

.hero-trader-section button {
    background-color: #0056b3;
    color: #fff;
    padding: 1rem 2rem;
    border: none;
    border-radius: 5px;
    font-size: 1.1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.hero-trader-section button:hover {
    background-color: #003d80;
}

.section {
    padding: 2rem 1rem;
    text-align: center;
}

.section h2 {
    font-size: 2rem;
    margin-bottom: 1rem;
}

.cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 1rem;
    padding: 1rem;
}

.card {
    background-color: #333;
    border: 1px solid #555;
    border-radius: 10px;
    padding: 2rem;
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.card:hover {
    transform: translateY(-5px) scale(1.02);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
}

.card h3 {
    margin-bottom: 1rem;
    font-size: 1.5rem;
}

.card p {
    font-size: 1.1rem;
    margin-bottom: 1rem;
}

.faq {
    text-align: left;
    max-width: 800px;
    margin: 0 auto;
}

.faq h3 {
    font-size: 1.3rem;
    margin-bottom: 0.5rem;
}

.faq p {
    font-size: 1rem;
    margin-bottom: 1rem;
}

footer {
    text-align: center;
    padding: 2rem;
    background-color: #1a1a1a;
    color: #aaa;
}
.testimonials {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 1rem;
    padding: 1rem;
}

.testimonial-card {
    background-color: #444;
    border: 1px solid #555;
    border-radius: 10px;
    padding: 1.5rem;
    text-align: center;
    color: #ddd;
    font-style: italic;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.testimonial-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.get-started-section {
    background-color: #0056b3;
    color: #fff;
    text-align: center;
    padding: 2rem 1rem;
    margin-top: 2rem;
}

.get-started-section h2 {
    font-size: 2rem;
    margin-bottom: 1rem;
}

.get-started-section p {
    font-size: 1.2rem;
    margin-bottom: 1.5rem;
}

.get-started-section button {
    background-color: #003d80;
    color: #fff;
    padding: 1rem 2rem;
    border: none;
    border-radius: 5px;
    font-size: 1.1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.get-started-section button:hover {
    background-color: #00275e;
}
