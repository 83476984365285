/* Main container */
.about-container {
    font-family: 'Arial', sans-serif;
    max-width: 100%;
    margin: 30px auto;
    padding: 20px;
    background: linear-gradient(135deg, #2a2a2a, #1c1c1c);
    border-radius: 10px;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
    overflow: hidden;
}

/* Section styling */
.about-intro, .about-section, .contact-us {
    padding: 30px 0;
    border-bottom: 1px solid #444;
    animation: fadeIn 1s ease both;
}

.about-section:last-child {
    border-bottom: none;
}

/* Fade-in animation */
@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Flexbox layout */
.about-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px;
}

.about-content.reverse {
    flex-direction: row-reverse;
}

/* Main image styling */
.about-main-image {
    width: 100%;
    max-width: 350px;
    margin-bottom: 20px;
    border-radius: 10px;
    object-fit: cover;
    flex: 1;
    transition: transform 0.3s ease;
}

.about-main-image:hover {
    transform: scale(1.05);
}

/* Text content styling */
.about-text {
    flex: 2;
    margin: 0 20px;
    color: #e0e0e0;
}

/* Heading styles */
.about-content h1, h2 {
    font-size: 28px;
    margin-bottom: 20px;
    color: #ffffff;
    text-shadow: 2px 2px 5px black;
    font-weight: 700;
    padding-bottom: 10px;
}

p, li {
    font-size: 18px;
    line-height: 1.8;
    color: #e0e0e0;
    margin-bottom: 20px;
    font-weight: 400;
    text-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
}

/* Feature list styling */
.feature-list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    padding: 0;
    list-style: none;
    justify-content: space-between;
}

.feature-list li {
    flex: 1 1 calc(33.33% - 20px);
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.3);
    padding: 20px;
    border-radius: 10px;
    background: linear-gradient(135deg, #2a2a2a, #434343);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    position: relative;
    overflow: hidden;
}

.feature-list li::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.1);
    opacity: 0;
    transition: opacity 0.3s ease;
}

.feature-list li:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.4);
    background: linear-gradient(135deg, #434343, #2a2a2a);
}

.feature-list li:hover::before {
    opacity: 1;
}

.feature-list li h3 {
    margin-bottom: 15px;
    font-size: 20px;
    color: #ffffff;
    text-shadow: 2px 2px 5px black;
}

/* Media queries for responsiveness */
@media (max-width: 768px) {
    .about-content {
        text-align: center;
        flex-direction: column;
    }

    .about-main-image {
        margin-bottom: 20px;
        max-width: 100%;
    }

    .about-text {
        margin: 0;
    }

    .feature-list li {
        flex: 1 1 100%;
    }
}
