.footer {
    background-color: #333;
    color: #fff;
    padding: 20px 0;
    margin-top: 50px;
    text-align: center;
  }
  
  .footer-content {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .footer-content p {
    margin: 0;
  }
  
  .footer-navigation a {
    color: #fff;
    text-decoration: none;
    margin: 0 10px;
  }
  
  .footer-navigation a:hover {
    text-decoration: underline;
  }
  
  @media screen and (max-width: 768px) {
    .footer-content {
      flex-direction: column;
      align-items: center;
    }

    .footer-navigation {
      margin-top: 10px;
    }
    
  }