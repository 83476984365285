.contact-container {
    font-family: 'Arial', sans-serif;
    max-width: 1200px;
    margin: 40px auto;
    padding: 20px;
    background-color: #2a2a2a;
    border-radius: 5px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    color: #e0e0e0;
}

.contact-header {
    text-align: center;
    margin-bottom: 40px;
}

h1, h2 {
    font-size: 24px;
    margin-bottom: 15px;
    color: #f0f0f0;
    text-shadow: 1px 1px 1px black;
    font-weight: 600;
}

p {
    font-size: 16px;
    line-height: 1.6;
    margin-bottom: 15px;
}

.input-group {
    margin-bottom: 20px;
}

label {
    display: block;
    margin-bottom: 10px;
}

input[type="text"], input[type="email"], textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #555;
    border-radius: 5px;
    background-color: #333;
    color: #e0e0e0;
}

.contact-form .submit-button{
    display: block;
    margin: 20px 0;
    padding: 10px 20px;
    background-color: #007BFF; 
    border: none;
    border-radius: 5px;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.submit-button:hover {
    background-color: #0056b3;
}

.contact-info, .office-hours, .faq-section, .testimonials {
    margin-top: 40px;
    padding: 20px;
    background-color: #333;
    border-radius: 5px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
}
.social-media-links{
    margin-top: 40px;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
}

.testimonials p {
    font-style: italic;
}


input[type="text"]:focus, input[type="email"]:focus, textarea:focus {
    outline: none;
    border-color: #00afff;
}

.social-media-links {
    display: flex;
    justify-content: space-around; /* Distributes space around items */
    flex-wrap: wrap; /* Allows items to wrap to the next line */
    padding: 0 1rem; /* Adds some padding to the left and right of the container */
    gap: 20px;

}

.link-button-contact {
    display: flex;
    align-items: center;
    padding: 0.8rem 2.5rem;
    border-radius: 8px;
    background-color: #007BFF;
    color: white;
    font-weight: 600;
    transition: background-color 0.3s ease;
    text-decoration: none;
    margin-bottom: 1rem; /* Adds space below each item when they wrap */
    text-align: center; /* Aligns text to center when items grow */
    flex-grow: 1;
    justify-content: center;
    min-width: 150px; /* or whatever minimum width you think looks best */
}

.link-button-contact .icon-Contact {
    font-size: 32px;
    margin-right: 0.5rem; /* Space between icon and text */
}

.link-button-contact:hover {
    background-color: #0056b3;
}

/* Adjust the styles below based on your actual FAQ content */
.faq-section p {
    margin-bottom: 10px;
}

.faq-section a {
    color: #00afff;
    text-decoration: none;
}

.faq-section a:hover {
    text-decoration: underline;
}


.icon{
    color: #00afff;

}

.form-status.success {
    color: green;
}

.form-status.error {
    color: red;
}

