/* General Styling */
.progress-container {
    padding: 20px;
    background-color: #0d0d0d;
    color: white;
    min-height: 100vh;
    font-family: 'Inter', sans-serif;
  }
  
  /* Grid Layout */
  .grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    margin-top: 20px;
  }
  
  /* Card Styling */
  .card {
    background-color: #1a1a1a;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0px 4px 8px rgba(255, 255, 255, 0.1);
    transition: transform 0.2s ease-in-out;
  }
  
  .card:hover {
    transform: scale(1.02);
  }
  
  .card h2 {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 12px;
    color: #4ca7f8;
  }
  
  .stat-group p {
    font-size: 14px;
    margin: 6px 0;
    display: flex;
    justify-content: space-between;
  }
  
  .stat-group span {
    font-weight: bold;
  }
  
  .highlight {
    color: #4ca7f8;
  }
  
  .profit {
    color: #3fcf64;
  }
  
  .loss {
    color: #e63946;
  }
  
  .time-left {
    color: #f4b400;
  }
  
  /* Button */
  .end-btn {
    display: block;
    margin-top: 14px;
    background: linear-gradient(135deg, #ff416c, #ff4b2b);
    color: white;
    padding: 10px 16px;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    font-weight: bold;
    transition: background 0.3s ease-in-out;
    width: 100%;
    text-align: center;
  }
  
  .end-btn:hover {
    background: linear-gradient(135deg, #ff4b2b, #ff416c);
  }
  
  /* Trades List */
  .trades-list {
    margin-top: 15px;
    border-radius: 8px;
    background-color: #1a1a1a;
    padding: 10px;
  }
  
  .trade-item {
    display: flex;
    justify-content: space-between;
    padding: 12px 15px;
    margin-bottom: 5px;
    border-radius: 8px;
    font-size: 14px;
    font-weight: bold;
    box-shadow: 0px 2px 5px rgba(255, 255, 255, 0.1);
    transition: transform 0.2s ease-in-out;
  }
  
  .trade-item:hover {
    transform: scale(1.03);
  }
  
  .trade-pair {
    flex: 2;
    text-align: left;
  }
  
  .trade-direction {
    flex: 1;
    text-align: center;
    font-weight: bold;
    padding: 5px 10px;
    border-radius: 5px;
  }
  
  .trade-date {
    flex: 1;
    text-align: right;
    color: #ccc;
  }
  
  /* Buy/Sell Colors */
  .buy {
    background-color: #32a14e;
    color: black;
  }
  
  .sell {
    background-color: #e63946;
    color: white;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .grid {
      grid-template-columns: 1fr;
    }
  }
  