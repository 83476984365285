.page-description {
    color: #ccc;
    margin-bottom: 2rem; 
    font-size: 1rem; 
    line-height: 1.6; 
}

.page-description h3 {
    color: #007BFF; 
    font-size: 1.5rem; 
    margin-bottom: 1rem; 
}

.page-description h4 {
    color: #007BFF; 
    font-size: 1.2rem; 
    margin: 1rem 0; 
}

.page-description ul,
.page-description ol {
    margin-left: 2rem; 
    margin-bottom: 1rem; 
}

.page-description li {
    margin-bottom: 0.5rem; 
}

.page-description p {
    margin-bottom: 1rem; 
}

.controls input[type="range"] {
    -webkit-appearance: none;
    width: 100%;
    height: 8px; 
    background: #4b4b4b; 
    border-radius: 5px;
    outline: none;
}

.controls input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 20px; 
    height: 20px; 
    background-color: #007bff; 
    border-radius: 50%; 
    cursor: pointer;
    border: 2px solid #fff; 
}

.controls input[type="range"]::-moz-range-thumb {
    width: 20px;
    height: 20px;
    background-color: #007bff;
    border-radius: 50%;
    cursor: pointer;
    border: 2px solid #fff;
}
