/* Reset */
*, *::before, *::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/* Root styles for the hero component */
.hero-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 70vh; /* Adjust as necessary */
    padding: 2rem 5%; /* Padding from left and right */
    background-color: #2b2b2b; /* Light grayish background */
}

.motto-section {
    flex: 1;
    padding-right: 2rem; /* A bit of space between the text and the image */
}

.motto-section h1 {
    font-size: 2.8rem;
    margin-bottom: 1rem;
    color: #ffffff; /* Dark color */
}

.motto-section p {
    font-size: 1.2rem;
    margin-bottom: 2rem;
    color: #ffffff; /* Slightly faded text for contrast */
}

.hero-links {
    display: flex;
    gap: 1rem; /* Space between hero-links */
}

.link-button-hero {
    display: flex;
    align-items: center;
    padding: 0.8rem 1.5rem;
    border-radius: 8px;
    background-color: #007BFF;
    color: white;
    font-weight: 600;
    transition: background-color 0.3s ease;
    text-decoration: none;
}

.link-button-hero .icon-hero {
    margin-right: 0.5rem; /* Space between icon and text */
}
.link-button-hero .icon-hero-right {
    margin-left: 0.5rem; /* Space between icon and text */
}

.link-button-hero:hover {
    background-color: #0056b3;
}

.hero-section {
    flex: 1.5; /* More space for the image */
    min-height: 200px;
    min-width: 200px;
    background: url('../../../public/assets/images/image.png') no-repeat center center;
    background-size: cover;
    border-radius: 15px; /* Optionally add some rounded corners */
    box-shadow: 0 4px 10px rgba(0,0,0,0.1); /* A subtle shadow for depth */
}

/* ... Other styles remain unchanged ... */

/* Responsive design for mobile */
@media screen and (max-width: 768px) {
    .hero-container {
        flex-direction: column;
        height: auto;
        padding: 1rem; /* Reduce padding on smaller screens */
    }

    .motto-section {
        padding-right: 0;
        text-align: center;
        padding-bottom: 1rem; /* Add some space between text and buttons on small screens */
    }

    .hero-links {
        justify-content: center;
        margin-bottom: 1rem; /* Adjust space as necessary */
        gap: 0.5rem; /* Reduce gap between buttons on smaller screens */
        flex-direction: column; /* Stack buttons vertically if there’s not enough horizontal space */
    }

    .link-button-hero {
        width: 50%; /* Make buttons full width on small screens */
        margin: auto;
        text-align: center;
        justify-content: center;
    }

    .hero-section {
        width: 100%; /* Make image full width on small screens */
        height: 30vh; /* Adjust height of image on small screens */
        margin-bottom: 1rem; /* Adjust space as necessary */
        border-radius: 8px; /* Optional: Adjust border-radius if needed */
    }
}

