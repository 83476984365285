/* Container Styling */
.edit-card-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 30px;
    background-color: #1e1e1e; /* Darker background */
    border-radius: 8px;
    color: #f0f0f0; /* Lighter text */
    text-align: center;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.7); /* Shadow for depth */
}

.edit-card-container h1 {
    margin-bottom: 20px;
    font-size: 1.8em; /* Title font size */
    color: #007BFF; /* Bright blue title */
}

/* Form Styling */
.edit-card-form {
    display: flex;
    flex-direction: column;
    align-items: stretch; /* Full-width form elements */
}

.edit-card-form label {
    margin: 12px 0 4px; /* Space for labels */
    font-weight: bold; /* Bold text for labels */
}

/* Input, Textarea, Select Styling */
.edit-card-form input,
.edit-card-form textarea,
.edit-card-form select {
    padding: 12px;
    margin: 5px 0;
    border: 1px solid #333; /* Input borders */
    border-radius: 5px;
    background: #2b2b2b; /* Lighter background */
    color: #ffffff; /* White text */
    transition: border 0.3s ease; /* Border transition on focus */
}

.edit-card-form input:focus,
.edit-card-form textarea:focus,
.edit-card-form select:focus {
    border-color: #00bfff; /* Blue border on focus */
    outline: none; /* Remove default outline */
}

/* Button Styling */
.edit-card-form button {
    padding: 12px 25px;
    margin-top: 15px;
    border: none;
    border-radius: 5px;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease; /* Add transitions */
}

.edit-card-form button:hover {
    background-color: #0056b3;
    transform: translateY(-2px); /* Lift effect */
}

.edit-card-form button:disabled {
    background-color: #555; /* Disabled button color */
    cursor: not-allowed;
}

/* Take Profit Input Styling */
.edit-card-form .take-profit-input {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.edit-card-form .take-profit-input input {
    flex: 1;
    margin-right: 10px;
}

/* Submit Button Styling */
.submit-button-newCard {
    background-color: #0056b3; /* Consistent button color */
}

/* Image Preview Styling */
.image-preview-container {
    overflow: auto;
    white-space: nowrap;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0; /* Space around image previews */
}

.image-preview {
    margin-right: 10px;
    position: relative; /* Positioning for delete button */
}

.image-preview img {
    max-width: 150px;
    max-height: 150px;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5); /* Subtle shadow */
}

.add-take-profit{
    background-color: #007BFF;
}
.image-preview button {
    position: absolute;
    top: 5px;
    right: 5px;
    background: rgba(255, 0, 0, 0.7); /* Red delete button */
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    padding: 5px;
    font-size: 0.8em;
    transition: background 0.3s ease;
}

.image-preview button:hover {
    background: rgba(255, 0, 0, 1); /* Strong red on hover */
}

/* Custom Select Styles */
.edit-card-form select {
    appearance: none; /* Remove default styling */
    padding: 12px;
    margin: 5px 0;
    border: 1px solid #333;
    border-radius: 5px;
    background: #2b2b2b;
    color: #ffffff;
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="white"><polygon points="5,7 10,12 15,7"/></svg>'); /* Custom arrow */
    background-repeat: no-repeat;
    background-position: right 10px center;
    background-size: 10px;
    transition: border 0.3s ease, background-color 0.3s ease;
}

.edit-card-form select:focus {
    border-color: #00bfff;
    background-color: #333; /* Lighter background on focus */
}

.edit-card-form select:hover {
    cursor: pointer; /* Pointer on hover */
}

/* Icon Button Group Styling */
.icon-group {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
}

.select-button {
    background-color: #232323;
    color: #ffffff;
    border: 1px solid #444;
    padding: 12px 15px;
    margin: 15px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
}

.select-button:hover {
    background-color: #00bfff;
    transform: scale(1.05); /* Slight scaling on hover */
}

.select-button.active {
    background-color: #007BFF; /* Active state */
    color: white;
}

/* Loading Spinner Styling */
.loading-spinner {
    margin-top: 20px;
}
