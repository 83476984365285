.login-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.login-card {
    background-color: #2a2a2a;
    padding: 40px;
    border-radius: 8px;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
    width: 100%;
    max-width: 400px;
    text-align: center;
}

.login-header{
    color: #ffffff;
    font-size: 40px;
    margin-bottom: 20px;
}

.login-label {
    color: #ffffff;
    display: block;
    margin-bottom: 5px;
    text-align: left;
}

input[type=email].login-input, input[type=password].login-input{
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    font-size: 16px;
    background-color: #333;
    border: 1px solid #555;
}

input[type=email].login-input:focus, input[type=password].login-input:focus{
    outline: none;
    border-color: #007BFF;
}

button[type="submit"].login-button{
    background-color: #007BFF;
    color: #ffffff;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 20px;
    display: block;
    width: 100%;
}

button[type="submit"].login-button:hover {
    background-color: #0056b3;
}

.signup-text {
    margin-top: 20px;
    color: #ffffff;
}

.signup-text a {
    color: #007BFF;
}

.signup-text a:hover {
    text-decoration: underline;
}
