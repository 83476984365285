.forgot-password-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #121212;
}

.forgot-password-container input {
    font-size: large;
   
}

.forgot-password-card {
    background-color: #2a2a2a;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;
    text-align: center;
}

.forgot-password-form label {
    display: block;
    margin: 0.5rem 0 0.2rem;
    color: #f0f0f0;
}

input[type=email].forgot-password-input {
    width: 100%;
    padding: 0.8rem;
    margin-bottom: 1rem;
    border-radius: 4px;
    border: 1px solid #555;
    background-color: #333;
    color: #e0e0e0;
}

input[type=email].forgot-password-input:focus {
    outline: none;
    border-color: #007BFF;
}

button[type=submit].forgot-password-button {
    background-color: #007BFF;
    color: #ffffff;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 20px;
    display: block;
    width: 100%;
}

button[type=submit].forgot-password-button:hover {
    background-color: #0056b3;
}

.login-text {
    margin-top: 1rem;
    color: #ffffff;
}

.login-text a {
    color: #007BFF;
    text-decoration: underline;
}

.login-text a:hover {
    text-decoration: underline;
}

.form-status {
    margin-top: 1rem;
}

.form-status.success {
    color: green;
}

.form-status.error {
    color: red;
}

.verification-code-inputs {
    display: flex;
    justify-content: space-between;
    gap: 8px;
}

.verification-input {
    width: 40px;
    height: 40px;
    font-size: 20px;
    text-align: center;
    border: 2px solid #ccc;
    border-radius: 4px;
}

.verification-input:focus {
    border-color: #007bff;
    outline: none;
}

