.header {
    position: sticky;
    top: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 5%;
    background-color: #121212;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); 
    z-index: 1000; 
}

.logo {
    font-size: 1.5rem;
    font-weight: bold;
}


.navigation a {
    margin-left: 20px;
    text-decoration: none;
    color: inherit;
    transition: color 0.3s;

    &:hover {
        color: #007BFF;  
        font-weight: bold;

    }
}


.navigation a.active {
    color: #007BFF; 
    font-weight: bold;

}


.menu-icon {
    display: none;
    flex-direction: column;
    cursor: pointer;
}

.bar {
    background-color: #fff;
    margin: 3px 0;
    padding: 2px 20px;
    border-radius: 3px;
    transition: all 0.3s;
}

.bar1.open {
    transform: rotate(45deg) translate(5px, 5px);
}

.bar2.open {
    opacity: 0;
}

.bar3.open {
    transform: rotate(-45deg) translate(5px, -5px);
}

/* Responsive styles */
@media (max-width: 768px) {
    .menu-icon {
        display: flex;
    }

    .navigation {
        display: none;
        flex-direction: column;
        position: absolute;
        top: 60px; 
        right: 0;
        background-color: #121212;
        border-radius: 8px;
        box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    }

    .navigation a {
        margin: 10px;
    }

    .navigation.navigation-open {
        display: flex;
    }
}