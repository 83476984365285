.payment-container {
    max-width: 400px;
    margin: 0 auto;
    padding: 2rem;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
}

.payment-container h2 {
    margin-bottom: 1.5rem;
    font-size: 1.75rem;
    color: #333;
}

.payment-form {
    display: flex;
    flex-direction: column;
}

.payment-form label {
    margin-bottom: 0.5rem;
    font-size: 0.875rem;
    color: #555;
}

.payment-form input {
    padding: 0.75rem;
    margin-bottom: 1.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 0.875rem;
    color: #333;
}

.expiry-cvv {
    display: flex;
    justify-content: space-between;
}

.expiry-cvv div {
    width: 48%; /* Adjust width to ensure the input fields fit side by side */
}

.payment-btn {
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 4px;
    background-color: #28a745; /* Adjust to your brand color */
    color: #fff;
    font-size: 0.875rem;
    cursor: pointer;
    transition: background-color 0.3s;
}

.payment-btn:hover {
    background-color: #218838; /* Darken button color on hover */
}
