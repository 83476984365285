/* CardViewPage.css */

.card-view-container {
    background-color: #1e1e1e; /* Dark background */
    color: #f0f0f0; /* Light text color */
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.5);
    max-width: 800px;
    margin: 20px auto;
}

.header-section {
    text-align: center;
    margin-bottom: 20px;
}

.card-title {
    font-size: 2rem;
    color: #00aaff; /* Blue accent color */
}

.carousel {
    position: relative;
}

.carousel img {
    width: 25%;
    height: 25%;
    border-radius: 8px;
    cursor: pointer;
    transition: transform 0.3s ease;
}

.carousel img:hover {
    transform: scale(1.05); /* Zoom effect on hover */
}

.image-navigation {
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.image-navigation button {
    background-color: transparent;
    border: none;
    color: #00aaff; /* Blue accent color */
    font-size: 2rem;
    cursor: pointer;
    transition: color 0.3s ease;
    margin: 0 10px; /* Add spacing between buttons */
}

.image-navigation button:hover {
    color: #00d1ff; /* Lighter blue on hover */
}

.fullscreen-image-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
}

.fullscreen-image-container img {
    max-width: 90%;
    max-height: 90%;
    border-radius: 8px;
}

.details-section {
    margin-top: 20px;
}

.section-header {
    border-bottom: 2px solid #00aaff; /* Blue accent underline */
    padding-bottom: 10px;
    margin-bottom: 15px;
    font-size: 1.5rem; /* Adjust header size */
    display: flex;
    align-items: center; /* Align icon and text */
}

.section-header h2 {
    margin-left: 8px; /* Space between icon and text */
}

.card-details {
    background-color: #282838; /* Slightly lighter background for details */
    border-radius: 8px;
    padding: 15px;
    margin-bottom: 20px;
}

.card-details p {
    margin: 5px 0;
}

.take-profit-info {
    background-color: #282838;
    border-radius: 8px;
    padding: 15px;
}

.take-profit-list {
    list-style-type: none;
    padding-left: 0;
}

.take-profit-list li {
    margin: 5px 0;
}

.reached {
    color: #4caf50; /* Green for reached TP */
}

.unreached {
    color: #f44336; /* Red for unreached TP */
}

.error-message {
    background-color: #f44336; /* Red background for errors */
    color: white;
    padding: 15px;
    border-radius: 8px;
    text-align: center;
}

.price-info-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Two columns */
    gap: 15px; /* Space between items */
}

.price-info-item {
    background-color: #282838; /* Slightly lighter background for price info */
    border-radius: 8px;
    padding: 10px;
}


.stripe-donate-button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px; /* Adjust as needed */
}
