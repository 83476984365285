/* Base Styles */
.trader-profile-container {
    font-family: 'Arial', sans-serif;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem; /* Increased gap for better spacing */
    padding: 2rem;
    color: #fff;
    background-color: #121212;
}

/* Trader Basic Info */
.trader-basic-info {
    grid-column: 1 / span 2; /* Spans both columns */
    padding: 1.5rem;
    background-color: #1e1e1e;
    border-radius: 10px; /* More rounded corners */
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.3); /* Darker shadow */
    transition: transform 0.2s; /* Add transition for hover effect */
}

.trader-basic-info:hover {
    transform: translateY(-5px); /* Slight lift on hover */
}

.trader-basic-info h3 {
    margin-bottom: 1rem; /* More space below heading */
    color: #007BFF; /* Coral color for headings */
}

.trader-basic-info p {
    margin-bottom: 0.5rem;
    line-height: 1.6;
}

.trader-basic-info span {
    font-weight: bold;
}

/* Trader Blog Posts */
.trader-blog-posts {
    grid-column: 1 / span 2;
    padding: 1.5rem;
    background-color: #1e1e1e;
    border-radius: 10px;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.3);
    overflow-y: auto;
    max-height: 400px; /* Fixed max-height */
}

.trader-blog-posts h3 {
    margin-bottom: 1rem;
    color: #007BFF;
}

.post-navigation {
    display: flex;
    flex-direction: column; /* Changed to column for vertical list */
    gap: 1rem; /* Space between blog posts */
}

/* Trader Signals */
.trader-signals {
    grid-column: 1 / span 2;
    padding: 1.5rem;
    background-color: #1e1e1e;
    border-radius: 10px;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.3);
}

.trader-signals h3 {
    margin-bottom: 1rem;
    color: #007BFF;
}

.trader-stats {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr; /* Three columns for stats */
    gap: 2rem;
}

.stats-section {
    padding: 1rem;
    background-color: #2a2a2a;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    transition: transform 0.2s;
}

.stats-section:hover {
    transform: translateY(-5px);
}

.stats-section h4 {
    margin-bottom: 1rem;
    color: #007BFF;
}

.stats-section p {
    margin-bottom: 0.5rem;
    line-height: 1.6;
}

.stats-section span {
    font-weight: bold;
}

/* Loading Spinner */
.spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.spinner::after {
    content: '';
    width: 50px;
    height: 50px;
    border: 5px solid #f3f3f3;
    border-top: 5px solid #007BFF;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

/* Error Message */
.error-message {
    color: #ff4c4c;
    background-color: #1e1e1e;
    padding: 1rem;
    border-radius: 5px;
    text-align: center;
}

.green-text {
    color: rgb(0, 165, 0);
}
.red-text{
    color: red;

}

.profile-picture{
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
}

.post-navigation {
    display: flex;
    justify-content: space-between; /* Ensure items are spaced apart */
    align-items: center; /* Center items vertically */
    margin-top: 1rem; /* Adjust margin as needed */
}

.post-navigation-item-content {
    flex: 1; /* Take up remaining space */
}

.post-navigation-buttons {
    display: flex; /* Ensure buttons are in a row */
}

.button-post-next{
    background-color: #007BFF;
    border-radius: 5px;
    color: #fff;
    padding: 1rem 1.5rem; /* Adjust padding for better button size */
    border: none;
    font-size: 1.1rem;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
    margin-left: 50px; /* Add margin between buttons */
}
.button-post-prev {
    background-color: #007BFF;
    border-radius: 5px;
    color: #fff;
    padding: 1rem 1.5rem; /* Adjust padding for better button size */
    border: none;
    font-size: 1.1rem;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
    margin-right: 50px; /* Add margin between buttons */;
}


/* Responsive Styles */
@media screen and (max-width: 768px) {
    .trader-profile-container {
        grid-template-columns: 1fr;
        padding: 1rem;
    }

    .trader-blog-posts {
        max-height: none;
    }

    .trader-stats {
        grid-template-columns: 1fr; /* Single column for smaller screens */
    }

    .stats-section {
        margin-bottom: 1rem; /* Space between sections on small screens */
    }
}
