/* New Blog Post Styles */
.new-blog-post-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 30px;
    background-color: #1e1e1e; /* Darker background */
    border-radius: 8px;
    color: #f0f0f0; /* Lighter text */
    text-align: center;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.7); /* Shadow for depth */
}

.new-blog-post-container h2 {
    margin-bottom: 20px;
    font-size: 1.8em; /* Title font size */
    color: #007BFF; /* Bright blue title */
}

.form-group {
    margin-bottom: 1.5rem;
}

.form-group label {
    display: block;
    margin-bottom: 0.5rem;
    color: #ccc; /* Light gray label color */
}

.form-group input,
.form-group textarea {
    width: 100%;
    padding: 0.8rem;
    font-size: 1rem;
    border: 1px solid #333; /* Dark border color */
    border-radius: 5px;
    background-color: #2a2a2a; /* Dark input background color */
    color: #fff; /* White text color */
    transition: border-color 0.3s ease-in-out;
}

.form-group textarea {
    min-height: 200px;
    resize: vertical; /* Allow vertical resizing */
}

.form-group input:focus,
.form-group textarea:focus {
    outline: none;
    border-color: #0056b3; /* Accent color on focus */
}

button[type="submit"] {
    padding: 0.8rem 1.2rem;
    font-size: 1rem;
    border: none;
    border-radius: 5px;
    background-color: #0056b3; /* Accent color */
    color: #fff;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
}

button[type="submit"]:hover {
    background-color: #00448c; /* Darker accent color on hover */
}
