.profile-container {
  max-width: 800px;
  margin: auto;
  padding: 20px;
  background-color: #2c2c2c; /* Dark background */
  color: #fff; /* White text for readability */
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
}

.profile-container h1, .profile-container h2 {
  color: #4d9dfc; /* Light blue headings */
}

.user-details, .user-activity, .user-preferences, .subscription-actions {
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #444; /* Slightly lighter border */
  background-color: #333; /* Darker background for sections */
  border-radius: 5px;
}


.profile-container a {
  color: white; /* Link color */
  text-decoration: none;
}

.profile-container a:hover {
  text-decoration: underline; /* Underline on hover */
}


.preference-item {
  margin-bottom: 15px;
}

.preference-item label {
  font-size: 16px;
}

.preference-item select {
  margin-left: 10px;
  padding: 5px;
  font-size: 16px;
}

.frequency-options {
  display: flex;
  flex-wrap: wrap; /* Allows items to wrap to the next line */
  gap: 10px; /* Adds spacing between items */
  margin-top: 10px;
}

.frequency-options input[type="radio"] {
  display: none; /* Hide the default radio button */
}

.frequency-options label {
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  padding: 8px 15px;
  border: 1px solid #ccc; /* Light gray border */
  border-radius: 20px;
  background-color: #cfcfcf; /* White background */
  transition: background-color 0.3s, border-color 0.3s;
  font-size: 14px;
  color: #000; /* Black text color */
}

/* Custom circle for the radio button */
.frequency-options label::before {
  content: "";
  width: 20px;
  height: 20px;
  border: 2px solid #ccc; /* Light gray border */
  border-radius: 50%;
  margin-right: 10px;
  background-color: #fff; /* White background */
  transition: background-color 0.3s, border-color 0.3s;
}

/* Selected radio button */
.frequency-options input[type="radio"]:checked + label::before {
  background-color: #007bff; /* Blue circle */
  border-color: #007bff; /* Blue border */
  box-shadow: 0 0 5px #007bff; /* Glow effect */
}

/* Hover effect */
.frequency-options label:hover {
  background-color: #f0f0f0; /* Light gray background on hover */
  border-color: #007bff; /* Blue border on hover */
}

.frequency-options label:hover::before {
  border-color: #007bff; /* Blue border for the circle on hover */
}

/* Make labels stack on smaller screens */
@media (max-width: 768px) {
  .frequency-options {
      flex-direction: column; /* Stack items vertically */
      gap: 5px; /* Adjust spacing */
  }

  .frequency-options label {
      justify-content: center; /* Center-align the labels */
  }
}

.submit-btn {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
}

.submit-btn:hover {
  background-color: #0056b3;
}
