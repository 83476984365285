/* FAQ.css */
.faq-page-container {
    background-color: #1d1d1d; /* Dark background */
    color: #ffffff; /* Light text color */
    padding: 20px;
    border-radius: 8px;
    max-width: 800px;
    margin: 20px auto;
}

.faq-title {
    font-size: 2em;
    text-align: center;
    margin-bottom: 20px;
    color: #ffffff;
}

.faq-category {
    border: 1px solid #333; /* Slightly lighter border */
    border-radius: 8px;
    margin-bottom: 10px;
    overflow: hidden;
    
}

.faq-category-title {
    background-color: #2c2c2c; /* Darker background for category title */
    color: #007BFF;
    padding: 15px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.arrow {
    margin-left: 10px;
    font-size: 1.2em; /* Adjust arrow size */
}

.faq-question-list {
    padding: 15px;
    background-color: #3a3a3a; /* Darker background for question list */
}

.faq-question {
    margin-bottom: 10px;
}

.question {
    font-weight: bold;
    margin-bottom: 5px;
}

.answer {
    margin-left: 10px;
    color: #ccc; /* Slightly lighter color for the answer */
}
